export const withUnprovided = (
  unrpovideMethods: Array<(callback?: () => void) => void>,
  callback: () => void,
) => {
  const finalCallback = unrpovideMethods.reduce((finalCallback, unprovide) => {
    return () => unprovide(finalCallback)
  }, callback)

  finalCallback()
}
