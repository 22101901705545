import { RouteLocationNormalized, RouteRecordRaw, RouteRecordRedirect, RouteRecordSingleView, RouteRecordSingleViewWithChildren, RouterScrollBehavior } from 'vue-router'
import { Component, DefineComponent } from 'vue'
import { ComponentProps } from '@lasso/shared/types'

export type TypedRoute<T extends Component | DefineComponent> = RouteRecordRedirect | Omit<
  | RouteRecordSingleView
  | RouteRecordSingleViewWithChildren,
  'component' | 'props'
> & {
  component: T | (() => Promise<T>)
  props?: (to: RouteLocationNormalized) => ComponentProps<T>
}

/**
 * Checks types of the props of the router component.
 * @example
 * defineRoute({
 *   path: 'directsold/campaign/:campaignId/edit',
 *   name: RouteName.CampaignEdit,
 *   props: ({ params }) => ({
 *     campaignId: parseId(params.campaignId),
 *   }),
 *   component: async () => (await import('@monetization/components/ViewCampaignEdit')).ViewCampaignEdit,
 * })
 */
export const defineRoute = <T extends Component | DefineComponent>(route: TypedRoute<T>): RouteRecordRaw => {
  return route as RouteRecordRaw
}

/**
 * Parses route param into a number. See {@link defineRoute}.
 */
export const parseId = (param: string | string[] | undefined): number => {
  return Math.abs(parseInt(String(param), 10))
}

/**
 * Determines when to reset the scroll.
 */
export const createScrollBehavior = (): RouterScrollBehavior => (to, from) => {
  if (to.path !== from.path && !to.meta.modal && !from.meta.modal) {
    return { top: 0 }
  }

  return undefined
}
