import { DateTime } from 'luxon'

import { TimeZone } from '../consts/timeZones'

/**
 * Supported date format identifiers in the application.
 * - shortDate: MM/dd/yyyy (03/17/2023)
 * - shortDateTime: MM/dd/yyyy h:mm a (03/17/2023 2:34 AM)
 * - shortDateNumeric: M/d/yyyy (3/17/2023)
 * - longDate: MMMM d, yyyy (March 17, 2023)
 * - longDateAbbr: MMM d, yyyy (Mar 17, 2023)
 * - monthYear: MMMM yyyy (March 2023)
 * - monthYearAbbr: MMM yyyy (Mar 2023)
 * - monthYearNumeric: MM/yyyy (11/2023)
 * - monthYearShortNumeric: MM/yy (11/23)
 * - monthDayAbbr: MMM d (Mar 17)
 * - monthAbbr: MMM (Mar)
 * - longDateTime: MMMM d, yyyy h:mm a (March 17, 2023 2:34 AM)
 * - longDateTimeAbbr: MMM d, yyyy h:mm a (Mar 17, 2023 2:34 AM)
 * - longDateTimeAbbrSec: MMM d, yyyy h:mm:ss a (Mar 17, 2023 2:34:56 AM)
 * - longDateTimeAbbrSecTimeZone: MMM d, yyyy h:mm:ss a ZZZZ (Mar 17, 2023 2:34:56 AM EDT / Jan 15, 2023 7:00:00 AM EST)
 * - longDateTimeFull: EEEE, MMMM d, yyyy h:mm a (Friday, March 17, 2023 2:34 AM)
 * - longDateTimeFullAbbr: EEE, MMM d, yyyy h:mm a (Fri, Mar 17, 2023 2:34 AM)
 * - timeShort: h:mm a (2:34 AM)
 */
export type DateFormat =
  | 'shortDate'
  | 'shortDateTime'
  | 'shortDateNumeric'
  | 'longDate'
  | 'longDateAbbr'
  | 'monthYear'
  | 'monthYearAbbr'
  | 'monthYearNumeric'
  | 'monthYearShortNumeric'
  | 'monthDayAbbr'
  | 'monthAbbr'
  | 'longDateTime'
  | 'longDateTimeAbbr'
  | 'longDateTimeAbbrSec'
  | 'longDateTimeAbbrSecTimeZone'
  | 'longDateTimeFull'
  | 'longDateTimeFullAbbr'
  | 'timeShort'

/**
 * Map of date formats used in the application (see {@link DateFormat} type).
 */
const formats = {
  shortDate: 'MM/dd/yyyy', // 03/17/2023
  shortDateTime: 'MM/dd/yyyy h:mm a', // 03/17/2023 2:34 AM
  shortDateNumeric: 'M/d/yyyy', // 3/17/2023
  longDate: 'MMMM d, yyyy', // March 17, 2023
  longDateAbbr: 'MMM d, yyyy', // Mar 17, 2023
  monthYear: 'MMMM yyyy', // March 2023
  monthYearAbbr: 'MMM yyyy', // Mar 2023
  monthYearNumeric: 'MM/yyyy', // 11/2023
  monthYearShortNumeric: 'MM/yy', // 11/23
  monthDayAbbr: 'MMM d', // Mar 17
  monthAbbr: 'MMM', // Mar
  longDateTime: 'MMMM d, yyyy h:mm a', // March 17, 2023 2:34 AM
  longDateTimeAbbr: 'MMM d, yyyy h:mm a', // Mar 17, 2023 2:34 AM
  longDateTimeAbbrSec: 'MMM d, yyyy h:mm:ss a', // Mar 17, 2023 2:34:56 AM
  longDateTimeAbbrSecTimeZone: 'MMM d, yyyy h:mm:ss a ZZZZ', // Mar 17, 2023 2:34:56 AM EST
  longDateTimeFull: 'EEEE, MMMM d, yyyy h:mm a', // Friday, March 17, 2023 2:34 AM
  longDateTimeFullAbbr: 'EEE, MMM d, yyyy h:mm a', // Fri, Mar 17, 2023 2:34 AM
  timeShort: 'h:mm a', // 2:34 AM
} as const satisfies Record<DateFormat, string>

/**
 * Formats the date according to the specified format and timezone.
 *
 * @param inputDate - Date to format (object Date, ISO string or SQL date string)
 * @param formatType - Format to use (see {@link DateFormat} type)
 * @param outputTimeZone - Timezone for displaying the result (see {@link TimeZone} enum)
 * @param treatInputAsUTC - Should the input value be interpreted as UTC (important for strings without timezone)
 * @returns Formatted date string
 */
export const formatDate = (
  inputDate: Date | string | null | undefined,
  formatType: DateFormat,
  outputTimeZone: TimeZone | null = TimeZone.EST,
  treatInputAsUTC = false,
): string => {
  if (!inputDate) {
    return ''
  }
  const format = formats[formatType]

  const options = { zone: treatInputAsUTC ? 'utc' : 'local' }

  let valueDate: DateTime

  if (typeof inputDate === 'string') {
    const dateFromISO = DateTime.fromISO(inputDate, options)
    const parsedDate = dateFromISO.isValid ? dateFromISO : DateTime.fromSQL(inputDate, options)

    if (!parsedDate.isValid) {
      return inputDate
    }

    valueDate = parsedDate
  }
  else {
    valueDate = DateTime.fromJSDate(inputDate, options)
  }

  const dateWithZone = outputTimeZone ? valueDate.setZone(outputTimeZone) : valueDate

  return dateWithZone.toFormat(format)
}
